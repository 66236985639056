import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <StaticImage
          src="../images/dan_with_dog2.jpg"
          alt="Mel looking sad"
          layout="constrained"
          width={300}
          height={300}
          className="rounded-full mt-10"
        />
        <h1 className="mt-8 mb-0">Uh oh, this doesn't seem right.</h1>
      </div>
    </header>
    <main className="h-screen max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center" id="main">
      <p className="mt-3">Things broke I guess? We'll look into fixing it. For now head back to the <Link to="/" className="underline text-yellow-500">homepage.</Link></p>
    </main>
  </Layout>
)

export default NotFoundPage
